body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  background-color: #fff;
  height: 100%;
}

:root {
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  color: #525251;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 5px 5px 5px 0;
}

h1 {
  margin: 0;
  text-align: center;
  font-size: calc(7vw + 3vh);
  /* color: #414141e6; */
  color: #3f6e8d;
}


h2 {
  margin: 0;
  /* font-size: calc(7vw + 3vh); */
  font-size: calc(5vw + 3vh);
  /* color: #414141e6; */
  color: #3f6e8d;
  text-align: center;
}

h3 {
  color: #3f6e8d;
}

header {
  min-height: calc(25vh + 10vw);
  /* background: rgb(231, 226, 222); */
  /* background: linear-gradient(172deg, rgba(231, 226, 222, 1) 55%, rgba(255, 255, 255, 1) 80%); */
  /* background: linear-gradient(172deg, rgba(231, 226, 222, 1) 55%, rgba(255, 255, 255, 1) 55%); */
}

header.MuiPaper-elevation {
  backdrop-filter: blur(3px) !important;
  background: rgba(50, 50, 50, 0) !important;
  min-height: 10px;
}

header>div {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}



/* CARDS */
.card {
  background: #E6E2DE;
  border-radius: 10px;
  width: 200px;
  /* width: fit-content; */
  aspect-ratio: 1/1;
}

.card-header {}

.card-header>h4 {
  color: white;
}

.top-container {
  padding-top: 130px;
}

footer {
  /* background: linear-gradient(5deg, rgba(231, 226, 222, 1) 55%, rgba(255, 255, 255, 1) 80%); */
}

.wave-section {
  background-image: url(/public/img/wave.svg);
  /* background: #3A7CA5; */
  /* height: 500px; */
}

.color-section {
  background-color: #3774a3;
  margin-top: -5px;
  padding-top: 5px;
}

.trendlist {
  display: flex;
  flex-wrap: wrap;
  width: 60vw;
  background-color: white;
  padding: 10px;
  border-radius: 32px;
  margin: auto;
}
.menuButtonBox {
  display: none;
}
.logoBox {
  display: flex;
  align-items: center;
}
.css-5xe99f-MuiLinearProgress-bar1 {
  background-color: #3774a3!important;
}

.css-10hburv-MuiTypography-root {
  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: calc(4vw + 3vh);

  }
  .trendlist {
    width: 80vw;
  }
  .logoText {
    display: none;
  }
  .logoBox {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
  }
  .navBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .menuButtonBox {
    display: block;
  }
  .articleCard {
    margin: auto;
    width: 90vw;
  }
  .search-btn-container {
    width: 85vw;
  }
}
