.search-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60vw;
}
.search-btn-container>button {
    margin-left: 5px;
    display: inline-flex;
}
